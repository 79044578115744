export const menu = [
    {
        title: "Teams",
        icon: "mdi-basketball",
        toMobile: { name: "teams", params: { type: "academy" } },
        subMenu: [
            { title: "Academy", to: { name: "teams", params: { type: "academy" } } },
            { title: "Girls", to: { name: "teams", params: { type: "girls" } } },
            { title: "Boys", to: { name: "teams", params: { type: "boys" } } },
            { title: "Seniors", to: { name: "teams", params: { type: "seniors" } } },
            {
                title: "Previous Teams",
                to: { name: "teams", params: { type: "previous" } },
                official: true,
            },
            { title: "Training Times", to: { name: "trainingTimes" } },
        ],
    },
    {
        title: "Fixtures",
        icon: "mdi-calendar",
        toMobile: { name: "fixtures" },
        subMenu: [
            { title: "Current Season", to: { name: "fixtures" } },
            { title: "Calendar", to: { name: "calendar" } },
        ],
    },
    {
        title: "Information",
        icon: "mdi-newspaper-variant-outline",
        toMobile: { name: "news" },
        subMenu: [
            { title: "News", to: { name: "news" } },
            { title: "Gallery", to: { name: "gallery" } },
        ],
    },
    {
        title: "About",
        icon: "mdi-information-outline",
        toMobile: { name: "about.sponsors" },
        subMenu: [
            { title: "Our Sponsors", to: { name: "about.sponsors" } },
            { title: "Our Club", to: { name: "about.club" } },
            { title: "Our Volunteers", to: { name: "about.volunteers" } },
            { title: "Contact Us", to: { name: "about.contact" } },
        ],
    },
    {
        title: "Members",
        icon: "mdi-account-multiple",
        toMobile: { name: "member.registrations" },
        subMenu: [
            { title: "Registration", to: { name: "member.registrations" } },
            { title: "Documents", to: { name: "member.documents" } },
            { title: "Policies", to: { name: "member.policies" } },
            { title: "Shop", to: { name: "member.shop" } },
            { title: "Membership Fees", to: { name: "member.subs" } },
            // { title: 'Volunteers', to: { name: 'member.volunteer' } }
        ],
    },
    {
        title: "Camps",
        icon: "mdi-basketball-hoop-outline",
        toMobile: { name: "camps" },
        subMenu: [
            { title: "Camp Big Shots", to: { name: "member.camp" } },
            // { title: 'Advanced Basketball Clinic', to: { name: 'member.camp-skills' } }
        ],
    },
    {
        title: "Admin",
        icon: "mdi-account-cog-outline",
        admin: true,
        subMenu: [
            { title: "Camps", to: { name: "admin.camps" }, gdpr: true },
            {
                title: "Camp Registrations",
                to: { name: "admin.camps.registrations" },
                gdpr: true,
            },
            { title: "Competitions", to: { name: "admin.competitions" } },
            { title: "Fees", to: { name: "admin.fees" }, gdpr: true },
            //{ title: 'Galleries', to: { name: 'admin.users' } },
            { title: "Opponents", to: { name: "admin.opponents" } },
            { title: "Orders", to: { name: "admin.orders" } },
            { title: "Products", to: { name: "admin.products" } },
            {
                title: "Registrations",
                to: { name: "admin.registrations" },
                gdpr: true,
            },
            { title: "Users", to: { name: "admin.users" } },
            { title: "Venues", to: { name: "admin.venues" } },
            {
                title: "Volunteers",
                to: { name: "admin.volunteers" },
                gardaVetting: true,
            },
            { title: "Admin Tasks", to: { name: "admin.tasks" }, webAdmin: true },
        ],
    },
    {
        title: "My Profile",
        icon: "mdi-account-circle-outline",
        auth: true,
        last: true,
        subMenu: [
            { title: "My Orders", to: { name: "my.orders" }, auth: true },
            {
                title: "My Registrations",
                to: { name: "my.registrations" },
                auth: true,
            },
            { title: "Logout", to: { name: "logout" }, auth: true, last: true },
        ],
    },
    {
        title: "Login",
        icon: "mdi-login",
        auth: false,
        last: true,
        to: { name: "login" },
    },
];
