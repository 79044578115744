// import Vue from 'vue'
import { RouteConfig } from "vue-router";

const Home = () => import(/* webpackChunkName: "home" */ "../components/Home.vue");
const Teams = () => import(/* webpackChunkName: "teams" */ "../components/teams/Teams.vue");
const TeamDetail = () => import(/* webpackChunkName: "teams" */ "../components/teams/TeamDetail.vue");
const TrainingTimes = () => import(/* webpackChunkName: "teams" */ "../components/teams/TrainingTimes.vue");
const Fixtures = () => import(/* webpackChunkName: "fixtures" */ "../components/fixtures/Fixtures.vue");
const Calendar = () => import(/* webpackChunkName: "calendar" */ "../components/fixtures/Calendar.vue");
const NewsList = () => import(/* webpackChunkName: "information" */ "../components/news/NewsList.vue");
const NewsDetail = () => import(/* webpackChunkName: "information" */ "../components/news/NewsDetail.vue");
const Gallery = () => import(/* webpackChunkName: "information" */ "../components/gallery/GalleryList.vue");
const GalleryDetail = () => import(/* webpackChunkName: "information" */ "../components/gallery/GalleryDetail.vue");
const Users = () => import(/* webpackChunkName: "admin" */ "../components/admin/users/Users.vue");
const Opponents = () => import(/* webpackChunkName: "admin" */ "../components/admin/opponents/Opponents.vue");
const Venues = () => import(/* webpackChunkName: "admin" */ "../components/admin/venues/Venues.vue");
const VolunteerList = () => import(/* webpackChunkName: "admin" */ "../components/volunteers/Volunteers.vue");
const AdminTasks = () => import(/* webpackChunkName: "admin" */ "../components/admin/tasks/AdminTasks.vue");
const Competitions = () => import(/* webpackChunkName: "admin" */ "../components/admin/competitions/Competitions.vue");
const Camps = () => import(/* webpackChunkName: "admin" */ "../components/admin/camps/Camps.vue");

const Registrations = () =>
    import(/* webpackChunkName: "registration" */ "../components/registrations/Registrations.vue");
const Orders = () => import(/* webpackChunkName: "orders" */ "../components/orders/Orders.vue");
const Products = () => import(/* webpackChunkName: "admin" */ "../components/admin/products/Products.vue");
const Sponsors = () => import(/* webpackChunkName: "about" */ "../components/about/Sponsors.vue");
const Club = () => import(/* webpackChunkName: "about" */ "../components/about/Club.vue");
const Volunteers = () => import(/* webpackChunkName: "about" */ "../components/about/Volunteers.vue");
const Contact = () => import(/* webpackChunkName: "about" */ "../components/about/Contact.vue");
const Login = () => import(/* webpackChunkName: "auth" */ "../components/login/Login.vue");

// const ShopList = () => import(/* webpackChunkName: "members" */ '../components/shop/ShopList.vue')
const VolunteerForm = () => import(/* webpackChunkName: "members" */ "../components/volunteers/VolunteerForm.vue");
const Subs = () => import(/* webpackChunkName: "subs" */ "../components/subs/Subs.vue");
const Camp = () => import(/* webpackChunkName: "camp" */ "../components/camp/CampForm.vue");
const Camp2 = () => import(/* webpackChunkName: "camp" */ "../components/camp/CampForm2.vue");
const Documents = () => import(/* webpackChunkName: "document" */ "../components/documents/Documents.vue");
const Policies = () => import(/* webpackChunkName: "policy" */ "../components/policies/Policies.vue");
const PlayersPolicies = () => import(/* webpackChunkName: "policy" */ "../components/policies/PlayersPolicy.vue");
const ParentsPolicies = () => import(/* webpackChunkName: "policy" */ "../components/policies/ParentsPolicy.vue");
const GroupCommunicationsPolicy = () =>
    import(/* webpackChunkName: "policy" */ "../components/policies/GroupCommunicationsPolicy.vue");
const PhotographyPolicies = () =>
    import(/* webpackChunkName: "policy" */ "../components/policies/PhotographyPolicy.vue");
const VolunteersPolicies = () => import(/* webpackChunkName: "policy" */ "../components/policies/VolunteersPolicy.vue");
const AttendancePolicies = () => import(/* webpackChunkName: "policy" */ "../components/policies/AttendancePolicy.vue");
const GdprPolicies = () => import(/* webpackChunkName: "policy" */ "../components/policies/GdprPolicy.vue");
const FeesPolicies = () => import(/* webpackChunkName: "policy" */ "../components/policies/FeesPolicy.vue");
const CovidPolicies = () => import(/* webpackChunkName: "policy" */ "../components/policies/CovidPolicy.vue");
const ConsentPolicies = () => import(/* webpackChunkName: "policy" */ "../components/policies/ConsentPolicy.vue");

import { getModule } from "vuex-module-decorators";
import { ProfileModule } from "@/store/profile-module";
const profileModule = getModule(ProfileModule);

function requireAuth(to: any, _from: any, next: any) {
    if (!profileModule.profile.auth) {
        next({ name: "login", query: { returnuri: to.path } });
    } else {
        next();
    }
}

function requireAdmin(to: any, _from: any, next: any) {
    if (!profileModule.profile.admin) {
        next({ name: "login", query: { returnuri: to.path } });
    } else {
        next();
    }
}

function requireWebAdmin(to: any, _from: any, next: any) {
    if (!profileModule.profile.webAdmin) {
        next({ name: "login", query: { returnuri: to.path } });
    } else {
        next();
    }
}

export const routes: Array<RouteConfig> = [
    { path: "/", name: "home", component: Home, meta: { full: true } },
    { path: "/login", name: "login", component: Login },
    {
        path: "/logout",
        beforeEnter: (to, from, next) =>
            profileModule
                .logout()
                .then(() => profileModule.clearMyProfile())
                .then(() => next({ name: "login", query: to.params })),
        name: "logout",
    },
    { path: "/teams/:type", name: "teams", component: Teams },
    {
        path: "/teams/:type/details/:id",
        name: "teams.detail",
        component: TeamDetail,
    },
    {
        path: "/teams/:type/registrations/:id",
        name: "teams.registrations",
        component: Registrations,
        props: { type: "team" },
    },
    { path: "/training-times", name: "trainingTimes", component: TrainingTimes },

    { path: "/fixtures", name: "fixtures", component: Fixtures },
    { path: "/calendar", name: "calendar", component: Calendar },
    { path: "/news", name: "news", component: NewsList },
    { path: "/news/:id/:title?", name: "news.detail", component: NewsDetail },
    { path: "/gallery", name: "gallery", component: Gallery },
    {
        path: "/gallery/:id/:title?",
        name: "gallery.detail",
        component: GalleryDetail,
    },

    { path: "/about/our-sponsors", name: "about.sponsors", component: Sponsors },
    { path: "/about/our-club", name: "about.club", component: Club },
    {
        path: "/about/our-volunteers",
        name: "about.volunteers",
        component: Volunteers,
    },
    { path: "/about/contact-us", name: "about.contact", component: Contact },

    // {
    //     path: '/member/registrations',
    //     name: 'member.registrations',
    //     component: Registrations,
    //     props: { type: 'my' },
    //     beforeEnter: requireAuth
    // },
    {
        path: "/member/registrations",
        name: "member.registrations",
        beforeEnter() {
            location.href = "https://ongarchasers.clubforce.com/products/membership";
        },
    },
    { path: "/member/policies", name: "member.policies", component: Policies },
    {
        path: "/member/policies/players",
        name: "member.policies.players",
        component: PlayersPolicies,
    },
    {
        path: "/member/policies/parents",
        name: "member.policies.parents",
        component: ParentsPolicies,
    },
    {
        path: "/member/policies/group-communications",
        name: "member.policies.group-communications",
        component: GroupCommunicationsPolicy,
    },
    {
        path: "/member/policies/photography-social-media",
        name: "member.policies.photography",
        component: PhotographyPolicies,
    },
    {
        path: "/member/policies/volunteers",
        name: "member.policies.volunteers",
        component: VolunteersPolicies,
    },
    {
        path: "/member/policies/attendance",
        name: "member.policies.attendance",
        component: AttendancePolicies,
    },
    {
        path: "/member/policies/gdpr",
        name: "member.policies.gdpr",
        component: GdprPolicies,
    },
    {
        path: "/member/policies/fees",
        name: "member.policies.fees",
        component: FeesPolicies,
    },
    {
        path: "/member/policies/covid-19",
        name: "member.policies.covid",
        component: CovidPolicies,
    },
    {
        path: "/member/policies/consent",
        name: "member.policies.consent",
        component: ConsentPolicies,
    },
    {
        path: "/member/documents",
        name: "member.documents",
        component: Documents,
        beforeEnter: requireAuth,
    },
    {
        path: "/member/camp/shots",
        name: "member.camp",
        alias: "/camps",
        component: Camp,
        beforeEnter: requireAuth,
    },
    {
        path: "/member/camp/skills",
        name: "member.camp-skills",
        alias: "/camp-skills",
        component: Camp2,
        beforeEnter: requireAuth,
    },
    //{ path: '/member/shop', name: 'member.shop', component: ShopList, beforeEnter: requireAuth },
    {
        path: "/member/shop",
        name: "member.shop",
        beforeEnter() {
            location.href = "https://www.jfsports.ie/product-category/club-shop/ongar-chasers-basketball-club/";
        },
    },
    {
        path: "/member/subs",
        name: "member.subs",
        component: Subs,
        beforeEnter: requireAuth,
    },
    {
        path: "/member/volunteer",
        name: "member.volunteer",
        component: VolunteerForm,
        beforeEnter: requireAuth,
    },

    // MY PROFILE
    {
        path: "/my/orders",
        name: "my.orders",
        component: Orders,
        props: { type: "my" },
        beforeEnter: requireAuth,
    },
    {
        path: "/my/registrations",
        name: "my.registrations",
        component: Registrations,
        props: { type: "my" },
        beforeEnter: requireAuth,
    },

    // ADMIN
    {
        path: "/admin/camps",
        name: "admin.camps",
        component: Camps,
        beforeEnter: requireAdmin,
    },
    {
        path: "/admin/camps/registrations",
        name: "admin.camps.registrations",
        component: Orders,
        props: { type: "camp" },
        beforeEnter: requireAdmin,
    },
    {
        path: "/admin/competitions",
        name: "admin.competitions",
        component: Competitions,
        beforeEnter: requireAdmin,
    },
    {
        path: "/admin/users",
        name: "admin.users",
        component: Users,
        beforeEnter: requireAdmin,
    },
    {
        path: "/admin/opponents",
        name: "admin.opponents",
        component: Opponents,
        beforeEnter: requireAdmin,
    },
    {
        path: "/admin/venues",
        name: "admin.venues",
        component: Venues,
        beforeEnter: requireAdmin,
    },
    {
        path: "/admin/volunteers",
        name: "admin.volunteers",
        component: VolunteerList,
        beforeEnter: requireAdmin,
    },
    {
        path: "/admin/registrations",
        name: "admin.registrations",
        component: Registrations,
        props: { type: "all" },
        beforeEnter: requireAdmin,
    },
    {
        path: "/admin/fees",
        name: "admin.fees",
        component: Orders,
        props: { type: "fees" },
        beforeEnter: requireAdmin,
    },
    {
        path: "/admin/orders",
        name: "admin.orders",
        component: Orders,
        props: { type: "shop" },
        beforeEnter: requireAdmin,
    },
    {
        path: "/admin/products",
        name: "admin.products",
        component: Products,
        beforeEnter: requireAdmin,
    },
    {
        path: "/admin/tasks",
        name: "admin.tasks",
        component: AdminTasks,
        beforeEnter: requireWebAdmin,
    },

    // CATCHALL
    { path: "*", redirect: { name: "home" } },
];
